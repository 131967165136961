import React, { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const ReCancelledOttTasks = ({date, shift}) => {
    const [data, setData] = useState([])

    const totalTasksFinder = (data) => {
        const gigUsers = {};

        const finalArray = []
        // Iterate over the data
        data.forEach(task => {
            // Check if gigUserId exists
            if (task.gigUserId) {
                // Check if gigUserId object already exists
                const serviceName = String(task.serviceName.split('-')?.[0] ? task.serviceName.split('-')?.[0] : task.serviceName.split('_')[0]).toLowerCase()
                if (!gigUsers[serviceName]) {
                    // Create new gig user object
                    gigUsers[serviceName] = {
                        gigUserId: task.gigUserId,
                        serviceName: serviceName,
                        gigUserName: task.gigUserFirstName[0] + ' ' + task.gigUserLastName[0],
                        cancelledTasks: 0
                    };
                }
                // Increment cancelledTasks if currentStatus is "Cancelled"
                if (task.currentStatus === "Cancelled") {
                    gigUsers[serviceName].cancelledTasks++;
                }
            }
        });
        for(let key in gigUsers){
            if(gigUsers[key].cancelledTasks !== 0){
                finalArray.push(gigUsers[key])
            }
        }
        setData(finalArray);
    }

    const fetchData = () => {
        axios.post("https://api.subhopper.com/api/admin/fetch-task-from-task-histories", {
        date, shift
        }).then(res => {
        totalTasksFinder(res.data)
        }).catch(err => {
        console.log('error', err)
        })
    }

    let interval = null;
    useEffect(()=>{
        fetchData()
        interval = setInterval(()=>{
            fetchData()
        }, 15000)

        return () => {
            clearInterval(interval)
        }
    },[date, shift])

  return (
    <div style={{ height: '650px', width: '1400px' }}>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="serviceName" />
            <YAxis label={{ value: 'Tasks', angle: -90, position: 'insideLeft' }}  />
            <Tooltip 
                content={({ payload , label, active }) => {
                    if (active) {
                        return (
                            <div className="custom-tooltip" style={{backgroundColor:'white', fontSize:16, padding:10, borderRadius:8, textTransform:"capitalize", border:"1px solid gray"}}>
                                <p>{label} </p>
                                {payload.map((entry, index) => (
                                <span key={index} style={{ color: entry.color }}>
                                    {entry.value !== 0 &&
                                    <>
                                    {'Cancelled Tasks'} : {entry.value}<br/>
                                    </>
                                    }
                                </span>
                                ))}
                                
                            </div>
                        );
                    }
                    return null;
                }}
            />
            <Legend 
                formatter={(value, entry) => {
                    // Customize the legend labels based on your requirements
                    switch (value) {
                      case 'cancelledTasks':
                        return 'Cancelled Tasks';
                      default:
                        return value;
                    }
                }}
            />
            <Bar dataKey="cancelledTasks" barSize={20} stackId="a" fill="#e42026b2" />
            </BarChart>
        </ResponsiveContainer>
    </div>
  )
}

export default ReCancelledOttTasks