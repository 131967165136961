import React, { Component } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, ReferenceArea } from 'recharts';
import axios from 'axios';

const getAxisYDomain = (from, to, ref, offset, initialData) => {
  // Convert from and to time strings to indices
  const fromIndex = initialData.findIndex(item => item.time === from);
  const toIndex = initialData.findIndex(item => item.time === to);

  // Check if both from and to indices are valid
  if (fromIndex === -1 || toIndex === -1) {
    console.error("Invalid from or to time");
    return [];
  }

  // Slice the initialData array
  const refData = initialData.slice(fromIndex, toIndex + 1);

  // Find the min and max values of the specified reference property
  let [bottom, top] = [refData[0][ref], refData[0][ref]];
  refData.forEach((d) => {
    if (d[ref] > top) top = d[ref];
    if (d[ref] < bottom) bottom = d[ref];
  });

  // Apply offset and return the domain
  const bottomToReturn = (bottom | 0) - offset
  return [bottomToReturn < 0  ? 0 : bottomToReturn, (top | 0) + offset];
};
class RechartsGWLC extends Component {
  componentDidUpdate(prevProps, prevState) {
    if(this.props.shift !== prevProps.shift){
      return this.fetchTaskData()
    }
    if(this.props.date !== prevProps.date){
      return this.fetchTaskData()
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      gigTaskHistory: [],
      timeHolder: {},
      left: 'dataMin',
      right: 'dataMax',
      refAreaLeft: '',
      refAreaRight: '',
      top: 'dataMax+1',
      bottom: 'dataMin',
      xAxisDomain: ['dataMin', 'dataMax'],
      top2: 'dataMax+20',
      bottom2: 'dataMin-20',
      animation: true,
    };
    this.interval = undefined;
  }

  componentDidMount() {
    this.fetchTaskData();
    this.interval = setInterval(() => {
      this.fetchTaskData();
    }, 15000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  zoom() {
    let { refAreaLeft, refAreaRight } = this.state;
    const { gigTaskHistory } = this.state;
  
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      this.setState(() => ({
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }
  
    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
  
    // yAxis domain
    const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, 'Reassigned', 1, gigTaskHistory);
    // const [bottom2, top2] = getAxisYDomain(refAreaLeft, refAreaRight, 'Reassigned', 50, gigTaskHistory);

    // Update x-axis domain
    const xAxisDomain = [refAreaLeft, refAreaRight];
  
    this.setState(() => ({
      refAreaLeft: '',
      refAreaRight: '',
      gigTaskHistory: gigTaskHistory.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
      xAxisDomain,
    }));
  }

  zoomOut() {
    const { gigTaskHistory } = this.state;
    this.setState(() => ({
      gigTaskHistory: gigTaskHistory.slice(),
      refAreaLeft: '',
      refAreaRight: '',
      left: 'dataMin',
      right: 'dataMax',
      top: 'dataMax+1',
      bottom: 'dataMin',
      top2: 'dataMax+50',
      bottom2: 'dataMin+50',
    }));
  }

  fetchTaskData = async () => {
    const { date, shift } = this.props;
    if(!date){
      return
    }
    try {
      const response = await axios.post("https://api.subhopper.com/api/admin/gig-task-history", { date, shift });

      const resultsArray = [];
      const timeHolder = {}
      let pendingTasks = [];

        response.data.forEach((item, index) => {
         
          // const utcDate = new Date(item.createdAt); 
          // const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000); 
          // const time = localDate.getHours() + ':' + localDate.getMinutes() + ':' + localDate.getSeconds()

          // const utcDate = new Date(item.createdAt);
          // const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
          // const time = utcDate.getHours() + ':' + utcDate.getMinutes() + ':' + utcDate.getSeconds();
          const time = item.createdAt ? item.createdAt.split('T')[1].split('.')[0] : null;

          timeHolder[index] = time

          const status = item.currentStatus.toLowerCase() === 'created' ? 'Created' :
            item.currentStatus.toLowerCase() === 'reassigned' ? 'Reassigned' :
              item.currentStatus.toLowerCase() === 'picked' ? 'Picked' :
                item.currentStatus.toLowerCase() === 'cancelled' ? 'Cancelled' : null;
          const gigWorkersCount = item.activeUsersCount
          if (time && status) {
            const existingIndex = resultsArray.findIndex(entry => entry.time === time);

            pendingTasks = pendingTasks.filter(task => task.taskId !== item.taskId);

            const pendingTasksCount = Number(pendingTasks.length);

            if (existingIndex !== -1) {
              if (pendingTasks.length > 0 && status !== 'Created') {
                resultsArray[existingIndex].Pending += pendingTasksCount;
              }
              if (status === 'Created') {
                resultsArray[existingIndex].Pending += 1;
              } else if (status === 'Picked') {
                resultsArray[existingIndex].InProcess += 1;
              } else if (status === 'Reassigned') {
                resultsArray[existingIndex].Reassigned += 1;
              } else if (status === 'Cancelled') {
                resultsArray[existingIndex].Cancelled += 1;
              }
            } else {
              const entry = {
                index,
                time,
                Pending:
                  status === "Created"
                    ? pendingTasks.length + 1
                    : pendingTasks.length,
                InProcess: status === "Picked" ? 1 : 0,
                Reassigned: status === "Reassigned" ? 1 : 0,
                Cancelled: status === "Cancelled" ? 1 : 0,
                ActiveGigWorkers: gigWorkersCount,
              };
              resultsArray.push(entry);
            }

            if (status === 'Created') {
              pendingTasks.push(item);
            }
          }
        });
      this.setState({ gigTaskHistory: resultsArray, timeHolder:timeHolder });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }


  renderCustomXAxisLabel = (value) => {
    switch (value) {
      case 'InProcess':
        return 'In Process';
      case 'Reassigned':
        return 'Re-Assigned';
      case 'ActiveGigWorkers':
        return 'Active Gig Workers';
      case 'dataMax':
        return '';
      case 'dataMin':
        return '';
      default:
        return this.state.timeHolder?.[value] || '';
    }
  };

  render() {
    const { gigTaskHistory, left, right, refAreaLeft, refAreaRight, top, bottom } = this.state;
    return (
      <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%' }}>
        <button type="button" className="btn update" onClick={this.zoomOut.bind(this)}>
          Zoom Out
        </button>
        <div style={{ height: '600px', width: '1400px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={gigTaskHistory}
              onMouseDown={(e) => this.setState({ refAreaLeft: e.activeLabel })}
              onMouseMove={(e) => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}
              // eslint-disable-next-line react/jsx-no-bind
              onMouseUp={this.zoom.bind(this)}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {/* <XAxis domain={xAxisDomain} allowDataOverflow dataKey="time" tickFormatter={this.renderCustomXAxisLabel} /> */}
              <XAxis domain={[left, right]} allowDataOverflow dataKey="index" type='number' tickFormatter={this.renderCustomXAxisLabel} />
              <YAxis domain={[bottom, top]} yAxisId="1" type="number" label={{ value: 'Tasks', angle: -90, position: 'insideLeft' }}  />
              <Tooltip 
                content={({ payload , label, active}) => {
                  if (active && label && payload.length > 0) {
                      const nameKeys = {
                        ActiveGigWorkers: "Active Gig Workers",
                        Cancelled: "Cancelled",
                        InProcess: "In Process",
                        Pending: "Pending",
                        Reassigned: "Re Assigned",
                      }
                      return (
                          <div className="custom-tooltip" style={{backgroundColor:'white', fontSize:16, padding:10, borderRadius:8, textTransform:"capitalize", border:"1px solid gray"}}>
                              <p>Time : {this.state.timeHolder[label]} </p>
                              {/* <p style={{color:"purple", marginBottom:-1}}>Total Tasks : {payload?.[0]?.payload?.cancelledTasks + payload?.[0]?.payload?.totalTasksFound}</p> */}
                              {/* <p>Median : {payload[0].payload.median} Minutes</p> */}
                              {Object.entries(payload?.[0]?.payload).map((value) => {
                                if(value.includes('index') || value.includes('time')){
                                  return
                                }
                                return(
                                  <p style={{color:"purple", marginBottom:-1}}>{nameKeys?.[value[0]]} : {value[1]}</p>
                                )
                              })}
                              
                          </div>
                      );
                  }
                  return null;
              }}

              
              />
              <Legend
                formatter={(value, entry) => {
                  switch (value) {
                    case 'InProcess':
                      return 'In-Process';
                    case 'Reassigned':
                      return 'Re-Assigned';
                    case 'ActiveGigWorkers':
                      return 'Active Gig Workers';
                    default:
                      return value;
                  }
                }}
              />
              <Bar yAxisId="1" barSize={20} dataKey="Pending" stackId="a" fill="#f0c85b" />
              <Bar yAxisId="1" barSize={20} dataKey="InProcess" stackId="a" fill="#337a3d" />
              <Bar yAxisId="1" barSize={20} dataKey="Reassigned" stackId="a" fill="#b31727" />
              <Line yAxisId="1" type="monotone" dataKey="ActiveGigWorkers" stroke="#ff7300" />
              <Line yAxisId="1" type="monotone" dataKey="Cancelled" stroke="#360a04" />
              {refAreaLeft && refAreaRight ? (
              <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default RechartsGWLC;
