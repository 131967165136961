// methods and css imports 
import './App.css'
import 'chartjs-adapter-moment'

// Pages import
import { HomePage } from './pages'



const App = () => {
  return (
    <HomePage />
  )
}

export default App