import React, { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const ReGWLB = ({date, shift}) => {
    const [data, setData] = useState([])

    // it also finds, time for pending, reassigned, currently we need complete so we are just returning that 
    const findTimeToComplete = (taskData) => {
        // Group tasks by task ID
        const groupedTasks = {};
        taskData.forEach(task => {
            if (!groupedTasks[task.taskId]) {
                groupedTasks[task.taskId] = [];
            }
            groupedTasks[task.taskId].push(task);
        });
    
        // Process each group of tasks
        const processedTasks = [];
        Object.values(groupedTasks).forEach(async group => {
            // Sort tasks by timestamp
            group.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
            // group = group.reverse()
            const taskId = group[0].taskId

            // Check if there is Created, Picked, and Finished tasks
            const createdTask = group.find(task => task.currentStatus === 'Created');
            const pickedTask = group.find(task => task.currentStatus === 'Picked');
            // const cancelledTask = group.filter(task => task.currentStatus === 'Cancelled').length
            const finishedTask = group.find(task => task.currentStatus === 'Finished');
            const reassignedTask = group.find(task => task.currentStatus === 'Reassigned');
         
            // Calculate overall lifespan in minutes
            // const firstTaskTime = reassignedTask ? new Date(reassignedTask?.createdAt) : new Date(createdTask?.createdAt);
            // const lastTaskTime = new Date(finishedTask?.createdAt);
            // const overallLifespan = (lastTaskTime - firstTaskTime) / (1000 * 60);
    
            let timeToPickUp;
            let timeToComplete;
            if(!reassignedTask && createdTask && pickedTask){
                const startTime = new Date(createdTask.timestamp);
                const endTime = new Date(pickedTask?.timestamp);
    
                // Calculate time difference for timeToPickUp
                timeToPickUp = Math.abs((endTime - startTime) / (1000 * 60));
            }
    
            if (reassignedTask) {
                // Calculate time to pick up the task
                const mostRecentReassignedTime = new Date(reassignedTask.timestamp);
                const mostRecentPickedTaskAfterReassigned = group.find(task => task.currentStatus === 'Picked' && new Date(task.timestamp) > mostRecentReassignedTime);
    
                if (mostRecentPickedTaskAfterReassigned) {
                    const startTime = mostRecentReassignedTime;
                    const endTime = new Date(mostRecentPickedTaskAfterReassigned.timestamp);
    
                    // Calculate time difference for timeToPickUp
                    timeToPickUp = Math.abs((endTime - startTime) / (1000 * 60));
                }
            } 
    
            if (pickedTask && finishedTask) {
              
                // Calculate time difference for pending and timeToComplete
                let startTime;
                const pickUpTime = new Date(pickedTask.timestamp);
                const finishTime = new Date(finishedTask.timestamp);
    
    
                // Calculate time difference for pending
                if(createdTask){
                  startTime = new Date(createdTask.timestamp);
                  timeToPickUp = (pickUpTime - startTime) / (1000 * 60);
                }
    
                // Calculate time difference for timeToComplete
                timeToComplete = (finishTime - pickUpTime) / (1000 * 60);
              
            }
    
            if(taskId && (timeToPickUp || timeToComplete)){
                processedTasks.push({
                //   overallLifespan,
                //   firstTaskTime,
                //   lastTaskTime,
                //   cancelledTask,
                //   timeToPickUp:timeToPickUp ? Math.abs(timeToPickUp?.toFixed(2)) : 0,
                  timeToComplete:timeToComplete ? timeToComplete?.toFixed(2) : 0,
              });
            }
        });
    
        return processedTasks;
      };


    const medianFinder = (taskData) => {
        // Extract and sort the createdAt values
        const complatedData = findTimeToComplete(taskData)
        const data = complatedData.map(entry => parseFloat(entry.timeToComplete));
        data.sort((a, b) => a - b);
        // Calculate the median
        const length = data.length;
        const middleIndex = Math.floor(length / 2);
        
        let median;
        if (length % 2 === 0) {
            median = (data[middleIndex - 1] + data[middleIndex]) / 2;
        } else {
            median = data[middleIndex];
        }
        return median?.toFixed(2)
    }

    const totalTasksFinder = (data) => {
        const gigUsers = {};

        const finalArray = []
        const gigUsersTasks = {}
        // Iterate over the data
        
        data.forEach((task, index) => {
            // Check if gigUserId exists
            if (task.gigUserId) {
                // store all the tasks for that gig user

                if (!(gigUsersTasks[task.gigUserId])){
                    gigUsersTasks[task.gigUserId] = [task]
                } else {
                    gigUsersTasks[task.gigUserId] = [...gigUsersTasks[task.gigUserId], task]
                }

                // Check if gigUserId object already exists
                if (!gigUsers[task.gigUserId]) {
                    // Create new gig user object
                    gigUsers[task.gigUserId] = {
                        gigUserId: task.gigUserId,
                        gigUserName: task.gigUserFirstName[0] + ' ' + task.gigUserLastName[0],
                        totalTasksFound: 0,
                        cancelledTasks: 0,
                        completedTasks: 0
                    };
                }
                // Increment totalTasksFound for the gig user
                gigUsers[task.gigUserId].totalTasksFound++;
                // Increment cancelledTasks if currentStatus is "Cancelled"
                if (task.currentStatus === "Cancelled") {
                    gigUsers[task.gigUserId].cancelledTasks++;
                }
                if (task.currentStatus === "Finished") {
                    gigUsers[task.gigUserId].completedTasks++;
                }
            }
        });

        for(let key in gigUsers){
            const modifiedObject = {
                ...gigUsers[key],
                median: medianFinder(gigUsersTasks[key]),
                totalTasksFound: gigUsers[key].totalTasksFound - gigUsers[key].cancelledTasks
            }
            finalArray.push(modifiedObject)
        }
        // remove server cron from the array
        const filteredData = finalArray.filter(document=>document.gigUserId !== "660a5bb76081b06d83ce21ca")
        setData(filteredData);
    }

    const fetchData = () => {
        if(!date){
            return
        }
        axios.post("https://api.subhopper.com/api/admin/fetch-task-from-task-histories", {date,shift}).then(res => {
            totalTasksFinder(res.data)
        }).catch(err => {
            console.log('error', err)
        })
    }

    let interval = null;
    useEffect(()=>{
        fetchData()
        interval = setInterval(()=>{
            fetchData()
        }, 15000)

        return () => {
            clearInterval(interval)
        }
    },[date, shift])

    return (
        <div style={{ height: '650px', width: '1400px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="gigUserName" />
                <YAxis label={{ value: 'Tasks', angle: -90, position: 'insideLeft' }}  domain={[0, 20]}/>
                <Tooltip 
                    content={({ payload , label, active }) => {
                        if (active) {
                            const messageModifier = (value) => {
                                if(value.includes('.')){
                                  return value.split(".")[0] + ' Minutes ' + value.split('.')[1] + ' Seconds ' 
                                }
                                else{
                                  return value + ' Minutes ' + 0 + ' Seconds'
                                }
                              }
                            return (
                                <div className="custom-tooltip" style={{backgroundColor:'white', fontSize:16, padding:10, borderRadius:8, textTransform:"capitalize", border:"1px solid gray"}}>
                                    <p>{label} </p>
                                    {/* <p style={{color:"purple", marginBottom:-1}}>Total Tasks : {payload?.[0]?.payload?.cancelledTasks + payload?.[0]?.payload?.totalTasksFound}</p> */}
                                    {/* <p>Median : {payload[0].payload.median} Minutes</p> */}
                                    {payload?.map((entry, index) => (
                                    <span key={index} style={{ color: entry.color }}>
                                        {entry.value !== 0 &&
                                        <>
                                        {entry.name === 'completedTasks' ? 'Completed Tasks' :  entry.name === 'median' ? 'Median Time' : 'Cancelled Tasks'} : {String(entry?.value)?.includes('.') ? entry?.value?.split('.')[0] + ' Minutes ' + entry?.value?.split('.')[1] + ' Seconds' : entry?.value} <br/>
                                        </>
                                        }
                                    </span>
                                    ))}
                                    
                                </div>
                            );
                        }
                        return null;
                    }}
                />
                <Legend
                    formatter={(value, entry) => {
                        // Customize the legend labels based on your requirements
                        switch (value) {
                        case 'completedTasks':
                            return 'Completed Tasks';
                        case 'cancelledTasks':
                            return 'Cancelled Tasks';
                        case 'median':
                            return 'Median Time';
                        default:
                            return value;
                        }
                    }}
                />
                <Bar dataKey="completedTasks" barSize={20} fill="#066850b2" />
                <Bar dataKey="cancelledTasks" barSize={20} fill="#e42026b2" />
                <Bar dataKey="median" barSize={20} fill="#3720e4b1" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default ReGWLB