import React, { useState, useEffect } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios'
import { Spin } from 'antd'
import RePieCharts from './RePieCharts';


// stack bar=> time to pick up & time to complete
// y axis => minutes
// x axis => task name 


// api call in the backend with the specified date
// 1. from the date till today if date is from the past we will take out the tasks ✅
// 2. we will group the tasks based on the task id ✅
// 3. we will check when the createdAt was and when the task was completed 
// 4. if there is cancelled and reassigned for that task then we will consider the time from reassigned to pickup [this will serve as time to pick up]
// 5. from created at to finish or if there is reassigned then from after reassigned we have picked up so from that picked up we will take the time and calculate till the finish.


const RechartsTPC = ({date, shift}) => {
  const [data, setData] = useState([])
  const [taskDetails, setTaskDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  
  // process received data from api
  const processTaskData = async (taskData) => {
    // Group tasks by task ID
    const groupedTasks = {};
    taskData.forEach(task => {
        if (!groupedTasks[task.taskId]) {
            groupedTasks[task.taskId] = [];
        }
        groupedTasks[task.taskId].push(task);
    });


    // Process each group of tasks
    const processedTasks = [];
    Object.values(groupedTasks).forEach(async group => {
        // Sort tasks by timestamp
        group.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        // group = group.reverse()
        const taskId = group[0].taskId
        let serviceName = group[0].serviceName.includes('-') ? group[0].serviceName.split('-')[0] : group[0].serviceName
        serviceName = serviceName.includes('_') ? serviceName.split('_')[0] : serviceName
        // Check if there is Created, Picked, and Finished tasks
        const createdTask = group.find(task => task.currentStatus === 'Created');
        const pickedTask = group.find(task => task.currentStatus === 'Picked');
        const cancelledTask = group.filter(task => task.currentStatus === 'Cancelled').length
        const finishedTask = group.find(task => task.currentStatus === 'Finished');
        const reassignedTask = group.find(task => task.currentStatus === 'Reassigned');
        const userDetails = group.find(task => !(task.userFullName?.includes('undefined')))
        const gigWorkerDetails = group.find(task => 
          task.gigUserFirstName?.length > 0 &&
          !task.gigUserFirstName.includes('undefined') &&
          !task.gigUserLastName?.includes('undefined')
      );
        const gigWorkerName = gigWorkerDetails ? gigWorkerDetails?.gigUserFirstName?.[0] + ' ' + gigWorkerDetails?.gigUserLastName?.[0] : 'N/A'
        // Calculate overall lifespan in minutes
        
        const firstTaskTime = reassignedTask ? new Date(reassignedTask?.createdAt) : new Date(createdTask?.createdAt);
        const lastTaskTime = new Date(finishedTask?.createdAt);
        const overallLifespan = (lastTaskTime - firstTaskTime) / (1000 * 60);

        let timeToPickUp;
        let timeToComplete;
        if(!reassignedTask && createdTask && pickedTask){
            const startTime = new Date(createdTask.timestamp);
            const endTime = new Date(pickedTask?.timestamp);

            // Calculate time difference for timeToPickUp
            timeToPickUp = Math.abs((endTime - startTime) / (1000 * 60));
        }

        if (reassignedTask) {
            // Calculate time to pick up the task
            const mostRecentReassignedTime = new Date(reassignedTask.timestamp);
            const mostRecentPickedTaskAfterReassigned = group.find(task => task.currentStatus === 'Picked' && new Date(task.timestamp) > mostRecentReassignedTime);

            if (mostRecentPickedTaskAfterReassigned) {
                const startTime = mostRecentReassignedTime;
                const endTime = new Date(mostRecentPickedTaskAfterReassigned.timestamp);

                // Calculate time difference for timeToPickUp
                timeToPickUp = Math.abs((endTime - startTime) / (1000 * 60));
            }
        } 

        if (pickedTask && finishedTask) {
          
            // Calculate time difference for pending and timeToComplete
            let startTime;
            const pickUpTime = new Date(pickedTask.timestamp);
            const finishTime = new Date(finishedTask.timestamp);


            // Calculate time difference for pending
            if(createdTask){
              startTime = new Date(createdTask.timestamp);
              timeToPickUp = (pickUpTime - startTime) / (1000 * 60);
            }

            // Calculate time difference for timeToComplete
            timeToComplete = (finishTime - pickUpTime) / (1000 * 60);
          
        }

        if(taskId && (timeToPickUp || timeToComplete)){
            processedTasks.push({
              groupedData:group,
              taskId,
              serviceName:(userDetails?.userFullName?.length > 8 ? userDetails?.userFullName?.slice(0,8) : userDetails?.userFullName) + '_' + serviceName,
              userFullName:userDetails?.userFullName,
              overallLifespan,
              firstTaskTime,
              lastTaskTime,
              gigWorkerName,
              cancelledTask,
              timeToPickUp:timeToPickUp ? Math.abs(timeToPickUp?.toFixed(2)) : 0,
              timeToComplete:timeToComplete ? timeToComplete?.toFixed(2) : 0,
          });
        }
    });

    return processedTasks;
  };

  // Api call
  const fetchTaskFromTaskHistories = () => {
    try{
      axios.post('https://api.subhopper.com/api/admin/fetch-task-from-task-histories', {
        date , shift
      }).then(async (response) =>{
        const data = await processTaskData(response.data)
        setData(data)
      })
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchTaskFromTaskHistories()

    const interval = setInterval(()=>{
      fetchTaskFromTaskHistories()
    }, 15000)

    return () => {
      clearInterval(interval)
    }
    //eslint-disable-next-line
  },[date, shift])


  const barClickHandler = (data) => {
    const userDetails = data.find(task => !(task.userFullName?.includes('undefined')))
    setName(userDetails?.userFullName)
    setLoading(true);
    // Create a new array to avoid mutating the original data
    const clickedData = [...data];
    console.log('clickedData', clickedData)
    setTaskDetails(clickedData);
    setLoading(false);
  };

  // const CustomizedLabel = ({ x, y, value}) => {
  //   return(
  //       <g transform={`translate(${x},${y})`}>
  //         <rect x={0} y={-15} width={20} height={20} fill="#250000" />
  //         <text x={9} y={5} dy={-6} textAnchor="middle" fill="#fff" >
  //           C
  //         </text>
  //       </g>
  //     );
  // }

  return (
    <>
    <div style={{ height: '650px', width: '1400px' }}>
      <ResponsiveContainer width="100%" height="100%" >
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="serviceName" fontSize={12} angle={-45} textAnchor="end"/>
          <YAxis type="number" label={{ value: 'minutes', angle: -90, position: 'insideLeft' }}  domain={[0, 20]} /> 
          <Tooltip
            content={({ payload, label, active }) => {
              if (active) {
                function formatTime(timeString) {
                    const date = new Date(timeString);
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');
                    if(hours.includes('NaN')){
                      return 'N/A'
                    }
                    return `${hours}:${minutes}:${seconds} `;
                }
                function formatDate(dateString) {
                    const date = new Date(dateString);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const year = date.getFullYear();
                    if(day.includes('NaN')){
                      return 'N/A'
                    }
                    return ` ${day}/${month}/${year}`;
                }
                const taskStartDateTime = payload[0]?.payload?.firstTaskTime 
                const taskEndDateTime = payload[0]?.payload?.lastTaskTime 
                return (
                  <div className="custom-tooltip" style={{backgroundColor:'white', fontSize:13, padding:10, borderRadius:8, textTransform:"capitalize", border:"1px solid gray"}}>
                    
                    <p>{payload?.[0]?.payload?.userFullName} : {label?.split('_')[1]}</p>
                    Task total time : {(Number(payload?.[0]?.payload?.timeToComplete) + Number(payload[0]?.payload?.timeToPickUp))?.toFixed(2)} Minutes <br/>
                    Gig Worker Name : {payload?.[0]?.payload.gigWorkerName} <br/>
                    Task Start Date and Time : {formatDate(taskStartDateTime)} {formatTime(taskStartDateTime)} <br/>
                    Task End Date and time : {formatDate(taskEndDateTime)} {formatTime(taskEndDateTime)}  <br/>
                    {payload.map((entry, index) => {
                      const messageModifier = (value) => {
                        if(value.includes('.')){
                          return value.split(".")[0] + ' Minutes ' + value.split('.')[1] + ' Seconds ' 
                        }
                        else{
                          return value + ' Minutes ' + 0 + ' Seconds'
                        }
                      }
                      return (
                        <span key={index} style={{ color: entry.color }}>
                          
                          {entry.value !== 0 &&
                          <>
                            {entry.name === 'timeToPickUp' && 
                            <>
                              Time To Pick Up : {messageModifier(String(entry.value))}<br/>
                            </>
                            } 
                            {entry.name === 'timeToComplete' && 
                              <>
                                Time To Complete : {messageModifier(String(entry.value))} <br/>
                              </>
                            } 
                            {entry.name === 'cancelledTask' &&  
                              <>
                               Cancelled Task : {entry.value} {'task' } <br/>
                              </>
                            } 
                          </>
                          }
                        </span>
                      )})
                    } 
                    
                    
                   
                  </div>
                );
              }
              return null;
            }}
          />
          <Legend
            layout="vertical" // Set the layout to vertical to stack the legend items vertically
            verticalAlign="top" // Align the legend to the bottom of the chart
            align="center" // Align the legend items to the center
            formatter={(value, entry) => {
              // Customize the legend labels based on your requirements
              switch (value) {
                case 'timeToComplete':
                  return 'Time To Complete ';
                case 'timeToPickUp':
                  return 'Time To Pick Up';
                case 'cancelledTask':
                  return 'Cancelled Task';
                default:
                  return value;
              }
            }}
          />
          <Bar onClick={(e)=>barClickHandler(e.groupedData)} barSize={20} dataKey="timeToPickUp" stackId="a" fill="#f0c85b" />
          <Bar onClick={(e)=>barClickHandler(e.groupedData)} barSize={20} dataKey="timeToComplete" stackId="a" fill="#337a3d" />
          <Bar onClick={(e)=>barClickHandler(e.groupedData)} barSize={20} dataKey="cancelledTask" fill="#b31727" />
          {/* <Bar barSize={20} dataKey="Reassigned" stackId="a" fill="#b31727" /> */}
          {/* <Line type="monotone" dataKey="ActiveGigWorkers" stroke="#ff7300" /> */}
        </ComposedChart>
      </ResponsiveContainer>
      <div style={{ textAlign: 'center' }}>
        <h2>Task Performance Chart</h2>
        <hr />
      </div>
    </div>

    {loading ?
     <div style={{minHeight:600, minWidth:1000, display:"flex", flexDirection:"column", justifyContent:"center"}}>
     <Spin />
    </div>
    :  
    <>
      {taskDetails.length > 0 &&
        <div style={{height:600, width:1400}}>
          <RePieCharts data={taskDetails} name={name}/>
          <hr />
        </div>
      }
    </>
    }
    </>
  )
}

export default RechartsTPC