import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Sector } from 'recharts';


const COLORS = ['#FFBB28', '#FF8042', '#00C49F',];

const RADIAN = Math.PI / 180;


function transformDataToObjectArray(data) {
  let result = [];
  
  for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          result.push({ ...element, key });
      }
  }
  
  return result;
}

function processStatusData(data) {
  let result = {};
  for (let i = 0; i < data.length - 1; i++) {
      let startDateTime = new Date(data[i].createdAt);
      let endDateTime = new Date(data[i + 1].createdAt);
      let currentStatus = data[i].currentStatus;
      let nextStatus = data[i + 1].currentStatus;
      let gigWorkerName = data[i].gigUserFirstName + ' ' + data[i].gigUserLastName
      let timeDiff = Math.abs(new Date(data[i + 1].createdAt) - new Date(data[i].createdAt));
      if (currentStatus === "Created" && nextStatus === "Picked") {
          result['pending'] = {
              name: "pending",
              gigWorkerName,
              startDateTime,
              endDateTime,
              value: timeDiff / (1000 * 60) // Time difference in minutes
          };
      } else if (currentStatus === "Picked" && nextStatus === "Cancelled") {
          if (!result['in-process']) {
              result['in-process'] = {
                  name: "in-process",
                  gigWorkerName,
                  startDateTime,
                  endDateTime,
                  value: timeDiff / (1000 * 60)
              };
          } else {
              let count = 1;
              while (result['in-process-' + count]) {
                  count++;
              }
              result['in-process-' + count] = {
                  name: "in-process",
                  gigWorkerName,
                  startDateTime,
                  endDateTime,
                  value: timeDiff / (1000 * 60)
              };
          }
      } else if (currentStatus === "Cancelled" && nextStatus === "Reassigned") {
          if (!result['cancelled']) {
              result['cancelled'] = {
                  name: "cancelled",
                  gigWorkerName,
                  startDateTime,
                  endDateTime,
                  value: timeDiff / (1000 * 60)
              };
          } else {
              let count = 1;
              while (result['cancelled-' + count]) {
                  count++;
              }
              result['cancelled-' + count] = {
                  name: "cancelled",
                  gigWorkerName,
                  startDateTime,
                  endDateTime,
                  value: timeDiff / (1000 * 60)
              };
          }
      } else if (currentStatus === "Reassigned" && nextStatus === "Picked") {
          if (!result['pending']) {
              result['pending'] = {
                  name: "pending",
                  gigWorkerName: ' ',
                  startDateTime,
                  endDateTime,
                  value: timeDiff / (1000 * 60)
              };
          } else {
              let count = 1;
              while (result['pending-' + count]) {
                  count++;
              }
              result['pending-' + count] = {
                  name: "pending",
                  gigWorkerName,
                  startDateTime,
                  endDateTime,
                  value: timeDiff / (1000 * 60)
              };
          }
      } else if (currentStatus === "Picked" && nextStatus === "Finished") {
          if (!result['in-process']) {
              result['in-process'] = {
                  name: "in-process",
                  gigWorkerName,
                  startDateTime,
                  endDateTime,
                  value: timeDiff / (1000 * 60)
              };
          } else {
              let count = 1;
              while (result['in-process-' + count]) {
                  count++;
              }
              result['in-process-' + count] = {
                  name: "in-process",
                  gigWorkerName,
                  startDateTime,
                  endDateTime,
                  value: timeDiff / (1000 * 60)
              };
          }
      }
  }
  const finalData = transformDataToObjectArray(result)

  return finalData;
}

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent , name}) => {

  const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x+10} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${name.includes('Picked') ? 'In-Process' : name.includes('Created') || name.includes('Reassigned')? 'Pending' : name.includes('Finished') ? 'In-Process' : name?.includes(' ') ? name.split(' ')[0] : name}: ${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

const RePieCharts = ({data=[], name=''}) => {

    const [serviceName, setServiceName] = useState('')
    const [totalTime, setTotalTime] = useState(undefined)
    const [graphData, setGraphData] = useState([])

    // Iterate over tasks

    useEffect(()=>{
        if(data.length > 0){
  
          const fetchData = async () => {
      
              // Calculate overall lifespan in minutes
              const firstTaskTime = new Date(data[data.length - 1].createdAt);
              const lastTaskTime = new Date(data[0].createdAt);
              const overallLifespan = (Math.abs(lastTaskTime - firstTaskTime) / (1000 * 60))?.toFixed(2);
          
              setTotalTime(overallLifespan ? overallLifespan : 'N/A')
  
              const statusResult = processStatusData(data);

              setGraphData(statusResult);
  
              function capitalizeFirstLetter(word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              }
  
              if(data?.[0]?.serviceName?.includes('-')){
                const name = capitalizeFirstLetter(data?.[0]?.serviceName?.split('-')[0])
                setServiceName(name)
              }
              if(data?.[0]?.serviceName?.includes('_')){
                const name = capitalizeFirstLetter(data?.[0]?.serviceName?.split('_')[0])
                setServiceName(name)
              }
              if(data?.[0]?.serviceName?.includes(' ')){
                const name = capitalizeFirstLetter(data?.[0]?.serviceName)
                setServiceName(name)
              }
          }
  
          fetchData()
        }
    },[data])
  
    
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Pie
          data={graphData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={200}
          fill="#8884d8"
          dataKey="value"
          startAngle={0} 
          endAngle={360} 
        >
          {/* 0 = pending, 1 = cancelled, 2 = completed */}
          {graphData.map((entry, index) => {
            const color = entry.name.includes('pending') ? 0 : entry.name.includes('cancelled') ? 1 : 2
            return(
              <Cell key={`cell-${index}`} fill={COLORS[color]} />
            )
          })}
        </Pie>
        <Tooltip 
            content={({ payload, label, active }) => {
            if (active) {   
                // const date = new Date(payload[0]?.payload?.createdAt)
              // Customize the tooltip content based on your requirements
              const { name, value, gigWorkerName, startDateTime , endDateTime, } = payload[0].payload;
              const startT = startDateTime?.getHours() + ':' + startDateTime?.getMinutes() + ':' + startDateTime?.getSeconds() 
              const startDate = startDateTime?.getDate() + '/' + (Number(startDateTime?.getMonth()) + 1) + '/' + startDateTime?.getFullYear()
              const endT = endDateTime?.getHours() + ':' + endDateTime?.getMinutes() + ':' + endDateTime?.getSeconds() 
              const endDate = endDateTime?.getDate() + '/' + (Number(endDateTime?.getMonth()) + 1) + '/' + endDateTime?.getFullYear()
              return (
                <div className="custom-tooltip" style={{padding:10, textTransform:'capitalize', height:'auto', width:300,  backgroundColor:"white", borderRadius:8, border:"1px solid gray"}}>
                  {payload.map((entry, index) => (
                    <span key={index} style={{ fontSize:14, color: entry.color }}>
                      Task total time : {totalTime} Minutes <br/> 
                      Gig Worker : {gigWorkerName.length < 5 ? 'N/A' : gigWorkerName} <br/> 
                      Status : {name.includes('Picked') ? 'In-Process' : name.includes('Finished') ? 'In-Process' : name.includes('Created') ? 'Pending' : name?.includes(' ') ? name.split(' ')[0] : name} <br/>
                      Start Time & Date : {startT} - {startDate}<br/>
                      End Time & Date : {endT} - {endDate} <br />
                    </span>
                  ))}
                </div>
              );
            }
            return null;
          }}
        />
      </PieChart>
      <div style={{ textAlign: 'center', marginTop:-70 }}>
        <h2>Fig : {name} {serviceName} Task History</h2>
      </div>
    </ResponsiveContainer>
  );
};

export default RePieCharts;
