import React, { useEffect, useState } from "react";
import { AllCharts, Ticket } from "../components";
import { Box } from "../assets/styles";
import axios from "axios";
// import EventChart from "../components/EventChart";

const colors = [
  { textColor: "white", bgColor: "navy" },
  { textColor: "white", bgColor: "teal" },
  { textColor: "white", bgColor: "black" },
  { textColor: "white", bgColor: "darkgreen" },
  { textColor: "white", bgColor: "crimson" },
  { textColor: "white", bgColor: "purple" },
  { textColor: "black", bgColor: "yellow" },
];

const HomePage = () => {
  const [taskStatuses, setTaskStatuses] = useState([]);

  const fetchTaskStatuses = () => {
    axios
      .get(
        "https://api.subhopper.com/api/admin/get-summarized-total-of-gig-tasks"
      )
      .then((res) => {
        setTaskStatuses(res.data.result.data);
      })
      .catch((err) => {
        alert("error while fetching all task and their statuses !");
      });
  };

  useEffect(() => {
    fetchTaskStatuses();
  }, []);

  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <AllCharts />
        <div style={{ marginTop: 30, display: "flex", flexWrap: "wrap" }}>
          {taskStatuses.map((item, index) => {
            return (
              <Box key={index} backgroundColor={colors[index]?.bgColor}>
                <p style={{ color: colors[index]?.textColor }}>
                  <span style={{ fontSize: 20 }}>{item.value} </span>
                  <br />
                  {item.heading}
                </p>
              </Box>
            );
          })}
        </div>
      </div>
      {/* <EventChart /> */}
      <Ticket />
    </>
  );
};

export default HomePage;
