import styled from 'styled-components'

export const Box = styled.div`
    height: 180px;
    width: 180px;
    border-radius: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "whitesmoke"};
`