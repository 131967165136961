import React, { useEffect, useState } from 'react';
import RechartsGWLC from './RechartsGWLC';
import RechartsTPC from './RechartsTPC';
import ReGWLB from './ReGWLB';
import ReCancelledOttTasks from './ReCancelledOttTasks';

const AllCharts = () => {
  const [date, setDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const [endDate, setEndDate] = useState()
  const [shift, setShift] = useState('Shift 1')
  const [currentChartValue, setCurrentChartValue] = useState('RCGWLC')

  useEffect(() => {
      const currentDate = new Date();

      // Get current year, month, and date
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Months are 0-indexed, so we add 1
      const day = currentDate.getDate();
      setMaxDate(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`)
      // Set the current date
      console.log(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`)
      setDate(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);

      // Set the next day's date
      const nextDayDate = new Date(currentDate);
      nextDayDate.setDate(nextDayDate.getDate());

      // Check if the month changes after adding 1 day
      const nextMonth = nextDayDate.getMonth() + 1;
      const nextYear = nextDayDate.getFullYear();
      const nextDay = nextDayDate.getDate();

      // If the next day's month is different, handle month/year change
      if (nextMonth !== month) {
          // Check if it's a leap year
          const isLeapYear = (nextYear % 4 === 0 && nextYear % 100 !== 0) || nextYear % 400 === 0;
          
          // If it's a leap year and the next month is February, set the date to 29
          if (isLeapYear && nextMonth === 2) {
              setEndDate(`${nextYear}-02-29`);
          } else {
              // Otherwise, set the date to the first day of the next month
              setEndDate(`${nextYear}-${nextMonth.toString().padStart(2, '0')}-01`);
          }
      } else {
          // If the month remains the same, simply set the next day's date
          setEndDate(`${nextYear}-${nextMonth.toString().padStart(2, '0')}-${nextDay.toString().padStart(2, '0')}`);
      }
  }, []); 

  return (
    <div style={{width:'auto'}}>
      <div style={{display:'flex', alignItems:"center"}}>
        <select value={currentChartValue} onChange={(e)=>setCurrentChartValue(e.target.value)} style={{height:39}}>
          <option value='RCGWLC'>Load Chart</option>
          <option value='GWLB'>Gig-Worker Leader Board</option>
          <option value='TPC'>Task Performance Chart</option>
          <option value='RECOT'>Cancelled Tasks Chart </option>
        </select>
        <select style={{width:'150px', height:39}} value={shift} onChange={(e)=>setShift(e.target.value)} >
          <option value='Shift 1'>Shift 1</option>
          <option value='Shift 2'>Shift 2</option>
        </select>
        <input style={{height:35}} type='date' value={date} max={maxDate} onChange={(e)=>setDate(e.target.value)} />
        {!(currentChartValue === 'RCGWLC' || currentChartValue === 'TPC' || currentChartValue === 'GWLB') &&
          <input style={{height:35}} type='date' value={endDate} onChange={(e)=>setEndDate(e.target.value)} />
        }

      </div>
   
          {currentChartValue === 'GWLB' ? 
          <>
            <ReGWLB date={date} shift={shift}/>
          </> :
          currentChartValue === 'RCGWLC' ? 
          <>
            <RechartsGWLC date={date} shift={shift}/>
          </> :
          currentChartValue === 'RECOT' ? 
          <>
            <ReCancelledOttTasks date={date} shift={shift}/>
          </> :
          currentChartValue === 'TPC' ?
          <>
            <RechartsTPC date={date} shift={shift}/>
          </> :
          <p style={{color:"crimson"}}>Error</p>
      }
    </div>
  );
};

export default AllCharts;
