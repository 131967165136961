// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fredoka-100 {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
.fredoka-200 {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
.fredoka-300 {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
.fredoka-400 {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
.fredoka-500 {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}


*{
  font-family: "Fredoka", cursive !important;
}

.ant-collapse-header-text , .ant-collapse-arrow{
  color: white !important;
}

.ant-collapse-content-box{
  max-height: 300px;
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;cACY;AACd;AACA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;cACY;AACd;AACA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;cACY;AACd;AACA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;cACY;AACd;AACA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;cACY;AACd;;;AAGA;EACE,0CAA0C;AAC5C;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".fredoka-100 {\n  font-family: \"Fredoka\", sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 100;\n  font-style: normal;\n  font-variation-settings:\n    \"wdth\" 100;\n}\n.fredoka-200 {\n  font-family: \"Fredoka\", sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 200;\n  font-style: normal;\n  font-variation-settings:\n    \"wdth\" 100;\n}\n.fredoka-300 {\n  font-family: \"Fredoka\", sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 300;\n  font-style: normal;\n  font-variation-settings:\n    \"wdth\" 100;\n}\n.fredoka-400 {\n  font-family: \"Fredoka\", sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 400;\n  font-style: normal;\n  font-variation-settings:\n    \"wdth\" 100;\n}\n.fredoka-500 {\n  font-family: \"Fredoka\", sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 500;\n  font-style: normal;\n  font-variation-settings:\n    \"wdth\" 100;\n}\n\n\n*{\n  font-family: \"Fredoka\", cursive !important;\n}\n\n.ant-collapse-header-text , .ant-collapse-arrow{\n  color: white !important;\n}\n\n.ant-collapse-content-box{\n  max-height: 300px;\n  overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
