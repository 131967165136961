import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import { Collapse } from 'antd';
import axios from 'axios'
import {Spin} from 'antd'


const Accordion = ({data, logsData}) => {

  useLayoutEffect(()=>{
    const element = document.getElementsByClassName('ant-collapse-content-box')[0];
    const htmlString = 
    ` UserId : ${data.internalData.userId} <br/>
      Email : ${data.internalData.generatedEmail}<br/> 
      Email Password: ${data.internalData.generatedEmailPassword}<br/> 
      Phone Number: ${data.internalData.phoneNumber.mdn}<br/> 
      Address : ${data.internalData.generatedAddress.street1}, ${data.internalData.generatedAddress.city}, ${data.internalData.generatedAddress.state}, ${data.internalData.generatedAddress.country}, ${data.internalData.generatedAddress.zip}.<br/> 
      Service Name : ${data.serviceName}<br/> 
      Gig Worker Name : ${data.gigWorkerName}<br/>
      Create At : ${data.createdAt}<br/>
      Picket At : ${data.pickedUpAt}<br/>
      Status : ${data.status}
    `;
    if (element) {
      element.innerHTML = htmlString;
      element.setAttribute('dangerouslySetInnerHTML', { __html: htmlString })
    }
  },[data])

  return (
    <>
      <Collapse 
        style={{backgroundColor:"black", color:"white !important"}} 
        items={[{key:1, label:'Customers data', children:`Names : ${data.name} Taken At : ${data.takenAt} Taken By: ${data.takenBy}`}]} 
        defaultActiveKey={['1']} 
        />
      <Collapse 
        className='something'
        defaultActiveKey={['0']} 
        style={{backgroundColor:"black", color:"white !important"}} 
        // items={[{key:2, label:'Logs', children:`<pre>${logsData}</pre>`}]} 
      >
        <h4 style={{paddingLeft:'20px', fontWeight:400, color:"white"}}>&gt;&nbsp;&nbsp;&nbsp;&nbsp;Logs {logsData.length < 40 && ' : No Logs Found'}</h4>
        <pre style={{color:"white", marginTop:'-10px', padding:"10px", paddingLeft:'20px', maxHeight:logsData.length > 40 ? 400 : 0, overflow:'scroll'}}>{logsData}</pre>
      </Collapse>
    </>
  );
};

const Ticket = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [taskDetails, setTaskDetails] = useState(false)
  const [tasksAndGigWorkerDetails, setTasksAndGigWorkerDetails] = useState([])
  const [tableData, setTableData] = useState([])
  const [searchResultData, setSearchResultData] = useState([])
  const [searchBy, setSearchBy] = useState('email')
  const [searchText, setSearchText] = useState('')
  const [searchResultLoading, setSearchResultLoading] = useState(false)
  const [logsData, setLogsData] = useState('')

  const timer = null;
  const inputRef = useRef()
  const changeHandler = (text) => {
    setSearchResultLoading(true)
    inputRef.current.focus();
    setSearchText(text)
    if(timer){
      clearTimeout(timer)
    }
    if(text.length < 1){
      return
    }
    setTimeout(()=>{
      let key='email'

      if(searchBy === 'Email'){
        key='email'
      }
      if(searchBy === 'Full Name'){
        key='fullName'
      }
      if(searchBy === 'Service Name'){
        key='serviceName'
      }
      if(searchBy === 'Gig Worker'){
        key='gigWorkerName'
      }
      if(searchBy === 'Task Created'){
        key='createdAt'
      }
      if(searchBy === 'Task Taken'){
        key='pickedUpAt'
      }
      if(searchBy === 'Status'){
        key='status'
      }
      const results = tableData.filter(item => item[key]?.toLowerCase()?.includes(text?.toLowerCase()))
      console.log('hodi baba', results)
      setSearchResultData(results)
      setSearchResultLoading(false)
    }, 1000)
  }

  const getTaskLogs = (taskId) => {
    axios({
      method:"POST",
      url:"https://api.subhopper.com/api/admin/taYqsk-IwD",
      data:{
        taskId,
      }
    }).then(res => {
      console.log('response from get task logs is', res.data)
      setLogsData(res?.data?.logs ? res?.data?.logs : 'no record found')
    }).catch(err =>{
      console.log("error from get tasks logs is", err)
    })
  }

  // Table component
  const TableComponent = () => {
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    
  
    const handleChange = ( filters, sorter) => {
      setFilteredInfo(filters);
      setSortedInfo(sorter);
    };
  
    // name of the services for the filters in headers
    const serviceNamesForFilters = [
      'Imdbtv',
      'Plex_tv',
      'Plutotv',
      'Tubi',
      'Vudu',
      'Acorntv',
      'Apple_tv',
      'Britbox',
      'Crunchyroll',
      'Dc_universe',
      'Disneyplus',
      'Fandor',
      'Hbomax',
      'Hulu',
      'Indieflix',
      'Mgmplus',
      'Mybundle_tv_free',
      'Netflix',
      'Paramountplus',
      'Peacock',
      'Prime',
      'Shudder',
      'Starz',
      'Youtube',
      'Gmail',
      'Zoho',
      'Mybundle_tv_free'
    ]    
    
    // Meta data like column name, filter conditions, sort
    const userDetailsMetaData = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email.length - b.email.length,
        sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: (a, b) => a.fullName.length - b.fullName.length,
        sortOrder: sortedInfo.columnKey === 'fullName' ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: 'Service Name',
        dataIndex: 'serviceName',
        key: 'serviceName',
        filters: serviceNamesForFilters.map(item => item.length > 0 && {text: item, value: item}),
        filteredValue: filteredInfo.serviceName || null,
        onFilter: (value, record) => record.serviceName.includes(String(value).toLowerCase()),
        sorter: (a, b) => a.serviceName.length - b.serviceName.length,
        sortOrder: sortedInfo.columnKey === 'serviceName' ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: 'Gig Worker Name',
        dataIndex: 'gigWorkerName',
        key: 'gigWorkerName',
        filters: tasksAndGigWorkerDetails.map(item => item.gigWorkerName > 0 && {text: item.gigWorkerName, value: item.gigWorkerName}),
        filteredValue: filteredInfo.gigWorkerName || null,
        onFilter: (value, record) => record.gigWorkerName.includes(value),
        sorter: (a, b) => a.gigWorkerName - b.gigWorkerName,
        sortOrder: sortedInfo.columnKey === 'gigWorkerName' ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: 'Task Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: 'Task Taken At',
        dataIndex: 'pickedUpAt',
        key: 'pickedUpAt',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
    
    ];

    return (
      <>
        {/* Table Code */}
        {searchResultLoading ? 
          <div style={{minHeight:600, minWidth:1000, display:"flex", flexDirection:"column", justifyContent:"center"}}>
            <Spin  size='large'/>
          </div>
          :
          <>
            {searchResultData.length === 0 && searchText.length > 1 && 'No results found for "' + searchText  + '" in "' + searchBy + '" showing all results.'}
            {searchResultData.length !== 0 && searchText.length > 1 && searchResultData.length + ' results found for "' + searchText  + '" in "' + searchBy }
            <Table 
              style={{cursor:'pointer'}}
              columns={userDetailsMetaData} 
              dataSource={searchResultData.length !== 0 ? searchResultData :  tableData} 
              onRow={(record)=>{
                return {
                  onClick: event => {
                      setShowDetails(true)
                      setTaskDetails(record)
                      getTaskLogs(record['taskId'])
                    }
                  }
                }
              } 
              onChange={handleChange} 
            />
          </>
        }
      </>
    );
  }

  // API'S
  const finalData = []
  const getTodaysTaskAndGigWorkers = () => {
    axios.get('https://api.subhopper.com/api/admin/sd8dW42-io0Xz2').then(res => {
      for(let mainObject of res.data){
        let generatedInfo = mainObject['generatedInfo'];
        let taskArray = mainObject['taskArray'];
        for(let task of taskArray){
          let new_entry = {
            "email": generatedInfo['user']["email"],
            "fullName": generatedInfo['user']["firstname"] + ' ' + generatedInfo['user']["lastname"],
            "taskTaken": task['taskStatus']["taskTaken"],
            "taskOutcome": task['taskStatus']["taskOutcome"],
            "createdAt": task['taskStatus']['createdAt'],
            "pickedUpAt": task['taskStatus']["pickedUpAt"],
            "serviceName": task['serviceObject']['serviceName'], 
            "gigWorkerName": task['taskStatus']["gigWorkerName"],
            "status": task['taskStatus']["taskOutcome"],
            "internalData": generatedInfo,
            "taskId": task['taskId']
         }
         finalData.push(new_entry)
        }
      }
    setTableData(finalData)
    setTasksAndGigWorkerDetails(res.data)
        
    }).catch(err => {
        alert('error while fetching all task and their statuses !')
    })
  }



  useEffect(()=>{
    getTodaysTaskAndGigWorkers()
    // eslint-disable-next-line
  },[])


  return (
    <div>
        <p>Search by Filters</p>
      <div style={{display:'flex', alignItems:"center"}}>
        <select style={{height:30, borderRadius:4}} onChange={(e)=>setSearchBy(e.target.value)}>
          <option value='Email'>Email</option>
          <option value='Full Name'>Full Name</option>
          <option value='Service Name'>Service Name</option>
          <option value='Gig Worker'>Gig Worker</option>
          <option value='Task Created'>Task Created</option>
          <option value='Task Taken'>Task Taken</option>
          <option value='Status'>Status</option>
        </select>

        <input ref={inputRef} value={searchText} style={{height:26, minWidth:200, borderRadius:4, outline:"none", borderWidth:0.5}} onChange={(e)=>changeHandler(e.target.value)} placeholder={`search by ${searchBy} ...`}/>
      </div>

        <br/>
        <br/>
        {/* render table component */}
        <TableComponent />
        {showDetails && 
          <>
            <Accordion data={taskDetails} logsData={logsData}/>
          </>  
        }
    </div>
  )
}

export default Ticket
